import React, { useState } from 'react';
import { Globe, Phone, Mail, MessageCircle } from 'lucide-react';

const languages = {
  pl: {
    label: 'Polski',
    about: 'To profesjonalny zespół, który rozumie, że każda sytuacja jest wyjątkowa, dlatego zapewniamy spersonalizowane wsparcie w legalizacji pobytu oraz kompleksową pomoc w kontaktach z polskimi urzędami.',
    contact: 'Kontakt'
  },
  ua: {
    label: 'Українська',
    about: 'Це професійна команда, яка розуміє, що кожна ситуація унікальна, тому ми надаємо персоналізовану підтримку в легалізації перебування та комплексну допомогу у взаємодії з польськими установами.',
    contact: 'Контакт'
  },
  en: {
    label: 'English',
    about: 'This is a professional team that understands that each situation is unique, therefore we provide personalized support in residence legalization and comprehensive assistance in dealing with Polish authorities.',
    contact: 'Contact'
  },
  de: {
    label: 'Deutsch',
    about: 'Dies ist ein professionelles Team, das versteht, dass jede Situation einzigartig ist, daher bieten wir personalisierte Unterstützung bei der Aufenthaltslegalisierung und umfassende Hilfe im Umgang mit polnischen Behörden.',
    contact: 'Kontakt'
  },
  ru: {
    label: 'Русский',
    about: 'Это профессиональная команда, понимающая, что каждая ситуация уникальна, поэтому предоставляем персонализированную поддержку в легализации пребывания и комплексную помощь во взаимодействии с польскими учреждениями.',
    contact: 'Контакт'
  },
  az: {
    label: 'Azərbaycan',
    about: 'Bu, hər vəziyyətin unikal olduğunu başa düşən peşəkar komandadır, buna görə də biz yaşayış yerinin leqallaşdırılmasında fərdi dəstək və Polşa hakimiyyət orqanları ilə qarşılıqlı əlaqədə hərtərəfli yardım göstəririk.',
    contact: 'Əlaqə'
  }
};

const App = () => {
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  
  return (
    <div className="min-h-screen bg-gray-900 text-white" style={{backgroundImage: "url('/logo.png')", backgroundSize: 'contain', backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}}>
      <div className="container mx-auto px-4 py-8">
        {!selectedLanguage ? (
          <div className="flex flex-col items-center justify-center min-h-screen">
            <div className="mt-auto mb-24 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {Object.entries(languages).map(([code, lang]) => (
                <button
                  key={code}
                  onClick={() => setSelectedLanguage(code)}
                  className="px-8 py-3 bg-gold bg-opacity-20 hover:bg-opacity-30 rounded-lg transition-all duration-300 text-gold"
                >
                  {lang.label}
                </button>
              ))}
            </div>
          </div>
        ) : (
          <div className="flex flex-col items-center justify-between min-h-screen py-12">
            <div className="text-center max-w-2xl mb-8">
              <h1 className="text-xl md:text-2xl mb-4 text-gold">MSTeam</h1>
              <p className="text-lg text-gold">{languages[selectedLanguage].about}</p>
            </div>
            
            <div className="mt-8 text-center">
              <h2 className="text-xl mb-4 text-gold">{languages[selectedLanguage].contact}</h2>
              <div className="flex flex-col space-y-4">
                <a href="tel:+48531488170" className="flex items-center justify-center space-x-2 text-gold hover:text-gold">
                  <Phone size={20} />
                  <span>+48 531 488 170</span>
                </a>
                <a href="mailto:contact@ms-team.eu" className="flex items-center justify-center space-x-2 text-gold hover:text-gold">
                  <Mail size={20} />
                  <span>contact@ms-team.eu</span>
                </a>
                <a href="https://wa.me/48531488170" className="flex items-center justify-center space-x-2 text-gold hover:text-gold">
                  <MessageCircle size={20} />
                  <span>WhatsApp</span>
                </a>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default App;